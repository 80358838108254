import { useMemo } from 'react';
import { useFavorites, useLastOrderDetails } from '@services/poms';
import { getShopifyIdFromGID, PRODUCT_TYPES, useTranslation } from '@utils/index';
import { CollectionSlider } from '@components';
import { useActiveFrame } from '@utils/hooks';
import { useRecommends } from '@services/shopify';

const getTranslatedTexts = translator => {
	return {
		suggestedLooks: translator('suggested-looks'),
		topsMadeForYou: translator('tops-made-for-you'),
	};
};

const ForYouCarousel = ({ customer }) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const { order } = useLastOrderDetails({ id: customer?.id });
	const customerId = getShopifyIdFromGID(customer?.id);
	const { data: favorites, isLoading: isFavoritesLoading } = useFavorites(customerId);
	const [activeFrame] = useActiveFrame();
	const parsedRecommendations = [];
	const MAX_RECS = 8;
	const productIds = useMemo(() => {
		if (!!order) {
			const topFrame = order.line_items.find(item => item.product_type.includes(PRODUCT_TYPES.TOP_FRAME));
			const topFrameId = topFrame?.product_id;
			return topFrameId ? [`gid://shopify/Product/${topFrameId}`] : [];
		} else {
			const lastFavoriteId = favorites.findLast(
				({ product }) => product.product_type.includes(PRODUCT_TYPES.TOP_FRAME) && product.status === 'active'
			)?.product.admin_graphql_api_id;
			return lastFavoriteId ? [lastFavoriteId] : [];
		}
	}, [favorites, order]);

	const { recommendations, isLoading } = useRecommends({
		objectIds: productIds,
		types: [PRODUCT_TYPES.TOP_FRAME],
		collectionHandle: 'best-sellers-1',
		maxRecommendations: MAX_RECS,
		basesToRecommendFor: [activeFrame],
	});

	recommendations?.forEach(recommendation => {
		// When there are no recommendations from Algolia we fall into the default collection,
		// the returned products from the collection contain all the available variables.
		// When there are recommendations from Algolia they contain only one variant.
		if (recommendation.type.includes(PRODUCT_TYPES.TOP_FRAME) && recommendation?.variants?.length > 1) {
			const variants = recommendation.variants.filter(variant => variant.option === activeFrame);
			recommendation = { ...recommendation, variants: variants };
		}
		parsedRecommendations.push(recommendation);
	});

	return (
		parsedRecommendations &&
		!isLoading && (
			<CollectionSlider
				iconType='star'
				header={translations.suggestedLooks}
				subHeader={translations.topsMadeForYou}
				products={parsedRecommendations}
				limit={MAX_RECS}
				isDataLoading={isFavoritesLoading}
				sliderType={'recommendations'}
			/>
		)
	);
};

export default ForYouCarousel;
